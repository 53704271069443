import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { slug: String }
  static targets = ["confirmed", "rejected", "confirmedSuccess", "rejectedSuccess", "rejectTextSubject", "rejectTextBody"]

  async accept() {
    const data = {
      permission_to_confirm: true
    }
    const result = await whisper(
      `/bookings/${this.slugValue}/permission_to_confirm`,
      data,
      "Error fetching activity"
    )
    if (result.permission_to_confirm == true) {
      this.confirmedTarget.style.display = 'none';
      this.confirmedSuccessTarget.style.display = '';
      notify_success("Booking accepted");
    } else {
      notify_danger("Something went wrong. Please contact support.");
    }
  }

  async acceptAndConfirm() {
    const data = {
      status: "is_confirmed",
      actor: "account"
    }

    const result = await whisper(
      `/bookings/${this.slugValue}/status`,
      data,
      "Error fetching activity"
    )

    if (result.status == "is_confirmed") {
      this.confirmedTarget.style.display = 'none';
      this.confirmedSuccessTarget.style.display = '';
      notify_success("Booking accepted and confirmed");
    } else {
      notify_danger("Something went wrong. Please contact support.");
    }
  }

  async rejectSilently() {
    const data = {
      status: "is_rejected",
      actor: "account"
    }
    await this.reject(data);
  }

  async rejectWithEmail() {
    const data = {
      status: "is_rejected",
      actor: "account",
      subject: this.rejectTextSubjectTarget.value,
      body: this.rejectTextBodyTarget.value
    }
    await this.reject(data);
  }

  async reject(data) {
    const result = await whisper(
      `/bookings/${this.slugValue}/status`,
      data,
      "Error fetching activity"
    )

    if (result.status == "is_rejected") {
      this.rejectedTarget.style.display = 'none';
      this.rejectedSuccessTarget.style.display = '';
      notify_success("Booking rejected");
    } else {
      notify_danger("Something went wrong. Please contact support.");
    }
  }
}
