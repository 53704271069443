import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { slug: String, status: String }

  async changeStatus() {
    await whisper(
      `/bookings/${this.slugValue}/status`,
      {status: this.statusValue, actor: "guest"},
      "Something went wrong",
      "POST"
    )
    window.location.reload();
  }
}
